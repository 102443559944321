function getFourPreviousMonths(month) {
   const months = [];

   for (let i = 1; i <= 4; i++) {
      let previousMonth = month - i;

      if (previousMonth < 1) {
         previousMonth += 12;
      }

      months.unshift(previousMonth);
   }

   return months;
}

export {
   getFourPreviousMonths
}
