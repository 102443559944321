function countCouponUuidByMonth(array, targetUUID) {
   const result = {};
   const currentDate = new Date();
   const currentYear = currentDate.getFullYear();
   const currentMonth = currentDate.getMonth() + 1; // Adjusting because getMonth() returns from 0 to 11

   // Initialize all previous months of the current year with zero counts for the target UUID
   for (let month = 1; month <= currentMonth; month++) {
      const key = `${currentYear}-${month < 10 ? '0' + month : month}`; // Format "YYYY-MM"
      result[key] = 0;
   }

   // Iterate over each object in the array
   array.forEach(object => {
      if (object.couponUuid === targetUUID) {
         const date = new Date(object.date);
         const year = date.getFullYear();
         const month = date.getMonth() + 1; // Adjust so January is 1, not 0

         // Ensure counting is only for the current year and up to the current month
         if (year === currentYear && month <= currentMonth) {
            const key = `${year}-${month < 10 ? '0' + month : month}`; // Format "YYYY-MM"

            // Increment count for the month
            result[key]++;
         }
      }
   });

   return result;
}

function sumTheQuantitiesFoEqualMonths(objects) {
      const result = {};

      // Iterates over each object in the array
      objects.forEach(obj => {

         // Processes each key-value pair in the current object
         for (const [month, qty] of Object.entries(obj)) {

            // If the month does not yet exist in the result object, initialize with 0
            if (!result[month]) {
               result[month] = 0;
            }
            // Sum the qty to the value already existing or initialized for the month
            result[month] += qty;
         }
      });

      return result;
   }
export {
   countCouponUuidByMonth,
   sumTheQuantitiesFoEqualMonths
}
