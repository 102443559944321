import axiosClient from "@/axios";
import { db } from "../../../../../plugins/firebase";

const routeAuthenticated = "/auth/questions";
const questionsRef = db.collection("questions");

const index = async (pageSize = 10, isAllQuestions = false) => {
   try {
      let query = questionsRef.orderBy("createdAt", "desc");

      if (!isAllQuestions) {
         query = query.where("alreadyAnswered", "==", false);
      }

      const questionsSnapshot = await query.get();

      const questions = questionsSnapshot.docs.map((doc) => doc.data());

      const totalPages = questions.length / pageSize > 1 ? Math.ceil(questions.length / pageSize) : 1;
      const tokenPages = [];
      const finalResults = [];
      for (let i = 1; i <= totalPages; i++) {
         tokenPages.push({ page: i, token: "" });

         let internalArray = [];
         for (let j = 0; j < pageSize; j++) {
            const position = finalResults.length > 0 ? finalResults.length * (j + 10) : j ;
            if (questions[position] === undefined)
               break;

            internalArray.push(questions[position]);
         }
         finalResults.push(internalArray)
      }

      const result = {
         totalPages,
         data: finalResults,
         tokenPages
      };

      return result;
   } catch (error) {
      console.error("Erro ao buscar perguntas:", error);
      throw error;
   }
};

const get = async (uuid) => {
   return await axiosClient.get(`${routeAuthenticated}/get/${uuid}`);
};

const edit = async (question) => {
   return await axiosClient.put(`${routeAuthenticated}/edit/${question.uuid}`, question )
};

const search = async (question) => {
   const questions = await questionsRef.get();

   const results = [];
   questions.forEach((doc) => {
      let item = doc.data();

      if (item.question) {
         const questionNormalized = item.question.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
         const searchNormalized = question.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

         if (questionNormalized.includes(searchNormalized))
            results.push(item);
      }
   });

   const finalResults = [];
   finalResults.push(results);

   return finalResults;
};

export default {
   index,
   get,
   edit,
   search
};
