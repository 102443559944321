import { AUTH_TOKEN, USER_AUTHENTICATED } from '@/helpers'

export default {
   SET_USER (state, userData) {
      state.user.data.userId = userData.userId
      state.user.data.name = userData.name
      state.user.data.email = userData.email
      state.user.data.profile = userData.profile
      state.user.token = userData.token
      localStorage.setItem(AUTH_TOKEN, userData.token)
      localStorage.setItem(USER_AUTHENTICATED, JSON.stringify({ userId: userData.userId, name: userData.name, email: userData.email, profile: userData.profile }))
   },

   LOGOUT (state) {
      state.user.data = {}
      state.user.token = null
      localStorage.removeItem(AUTH_TOKEN)
      localStorage.removeItem(USER_AUTHENTICATED)
   }
}
