import axiosClient from '@/axios'
import {db} from "../../../../../plugins/firebase";

const routeAuthenticated = '/auth/partner-companies';
const partnerCompaniesRef = db.collection('partnerCompanies');
const partnerCompaniesBenefitsRelationRef = db.collection('partnerCompaniesBenefitsRelation');

const getAllPaginated = async (pageSize = 10, pageToken = '') => {
   return await axiosClient.get(`${routeAuthenticated}/get-all-paginated?pageSize=${pageSize}&pageToken=${pageToken}`);
}

const getPaginationInfo = async (pageSize) => {
   return await axiosClient.get(`${routeAuthenticated}/get-pagination-info?pageSize=${pageSize}`)
}

const getAllPartnerCompaniesBenefitsRelation = async () => {
   const partnerCompaniesRelation = await partnerCompaniesBenefitsRelationRef.get();

   const results = [];
   partnerCompaniesRelation.forEach((doc) => {
      let item = doc.data();

      results.push(item);
   });

   return results;
}

const get = async uuid => {
   return await axiosClient.get(`${routeAuthenticated}/get/${uuid}`);
}

const save = async (type, partnerCompany) => {
   if (type === 'create')
      return await axiosClient.post(`${routeAuthenticated}/create`, partnerCompany);
   else
      return await axiosClient.put(`${routeAuthenticated}/edit/${partnerCompany.uuid}`, partnerCompany);
}

const search = async (name) => {
   const partnerCompanies = await partnerCompaniesRef.get();

   const results = [];
   partnerCompanies.forEach((doc) => {
      let item = doc.data();

      if (item.name) {
         const partnerCompanyNormalized = item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
         const searchNormalized = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

         if (partnerCompanyNormalized.includes(searchNormalized))
            results.push(item);
      }
   });

   return results;
}

const destroy = async (uuid, allBenefits, allPartnerCompaniesBenefitsRelation) => {
   const data = {
      allBenefits,
      allPartnerCompaniesRelation: allPartnerCompaniesBenefitsRelation
   };
   return await axiosClient.delete(`${routeAuthenticated}/delete/${uuid}`, { data: data });
}

export default {
   getAllPaginated,
   get,
   save,
   search,
   destroy,
   getPaginationInfo,
   getAllPartnerCompaniesBenefitsRelation
}
