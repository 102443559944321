import questionService from "../services/question-service";

export default {
   getAllPaginatedQuestions: async ({commit}, { pageSize = 10, isAllQuestions = false} = {}) => {
      commit('SET_QUESTIONS_LOADING', true)

      try {
         const response = await questionService.index(pageSize, isAllQuestions);
         commit('SET_QUESTIONS', response.data)
         commit('SET_QUESTION_PAGINATION_INFO', { totalPages: response.totalPages, tokenPages: response.tokenPages });

      } catch (e) {
         console.log('Error get all questions: ', e)
      } finally {
         commit('SET_QUESTIONS_LOADING', false)
      }
   },

   getQuestion: async ({ commit }, uuid) => {
      commit("SET_CURRENT_QUESTION_LOADING", true);
      try {
         const response = await questionService.get(uuid);
         commit("SET_CURRENT_QUESTION", response.data);
         return response;
      } catch (e) {
         const { status } = e.response;
         console.error(status);
         console.error("Error getQuestion: ", e);
      } finally {
         commit("SET_CURRENT_QUESTION_LOADING", false);
      }
   },

   editQuestion: async ({}, question) => {
      try {
         const response = await questionService.edit(question);
         return response;
      } catch (e) {
         console.error("Error saveQuestion: ", e);
      }
      return response;
   },

   searchQuestions: async ({commit}, question) => {
      commit('SET_QUESTIONS_LOADING', true);

      try {
         const response = await questionService.search(question);

         console.log(response)

         commit('SET_QUESTIONS', response);
      } catch (e) {
         console.log('Error search questions: ', e)
      }
      finally {
         commit('SET_QUESTIONS_LOADING', false)
      }
   }
};
