import { db} from "../../../plugins/firebase";
import { getFourPreviousMonths } from "../../../utils/getThreePreviousMonths";
import { countCouponUuidByMonth, sumTheQuantitiesFoEqualMonths } from "../../../utils/reportServiceMethods";

const commentsRef = db.collection('comments');
const couponsRef = db.collection('coupons');
const usersRef = db.collection('users');
const signaturesRef = db.collection('signatures');

const getQtyOfTimesByMonthThePartnerCouponWasUsed = async (partnerId) => {
   const coupons = await couponsRef.get();
   const signatures = await signaturesRef.get();

   const myCouponsId = [];
   coupons.forEach((doc) => {
      let item = doc.data();

      if (item.userId === partnerId)
         myCouponsId.push(item.uuid);
   });

   //get the array with only couponId and the date
   let signaturesArrayWithCouponIdAndDate = [];
   signatures.forEach((doc) => {
      let item = doc.data();

      signaturesArrayWithCouponIdAndDate.push({ couponUuid: item.couponUuid, date: item.signedDate })
   });

   //get the qty of coupons by month
   let couponsIdByMonth = [];
   myCouponsId.forEach((couponId) => {
      couponsIdByMonth.push(countCouponUuidByMonth(signaturesArrayWithCouponIdAndDate, couponId));
   });

   //sum the qty of coupons by month
   const result = sumTheQuantitiesFoEqualMonths(couponsIdByMonth);

   return result;
}

const getTotalOfTimesThePartnerCouponWasUsed = async (partnerId) => {
   const coupons = await couponsRef.get();
   const signatures = await signaturesRef.get();

   // get the partner coupons
   const myCouponsId = [];
   coupons.forEach((doc) => {
      let item = doc.data();

      if (item.userId === partnerId)
         myCouponsId.push(item.uuid);
   });

   // count the signatures they have partner coupon id
   let total = 0;
   signatures.forEach((doc) => {
      let item = doc.data();

      const foundIt = myCouponsId.find((couponId) => couponId === item.couponUuid)
      if (foundIt !== undefined)
         total++;
   });

   return total;
}

const getTableInfo = async () => {
   const signatures = await signaturesRef.get();
   const coupons = await couponsRef.get();
   const users = await usersRef.where('profile', "==", "partner").get();

   const usersArray = [];
   users.forEach((doc) => {
      let item = doc.data();

      usersArray.push(item);
   });

   const arrayCoupons = [];
   coupons.forEach((doc) => {
      let item = doc.data();

      arrayCoupons.push({ uuid: item.uuid, name: item.name, userId: item.userId })
   })

   const now = new Date();
   const month = (now.getMonth() + 1);

   // get the couponsId of signatures array for accounting later
   const arrayUuidCoupons = [];
   signatures.forEach((doc) => {
      let item = doc.data();

      const dateString = item.signedDate;
      const date = new Date(dateString);
      const signedMonth = date.getMonth() + 1;

      if (month === signedMonth)
         arrayUuidCoupons.push(item.couponUuid);
   });

   // accounting coupons for the current month
   const results = [];
   arrayUuidCoupons.forEach((couponUuid) => {

      const qtyCoupons = arrayUuidCoupons.filter(item => item === couponUuid).length;
      const coupon = arrayCoupons.find((item) => item.uuid === couponUuid);

      if (coupon !== undefined) {
         const hasIncluded = results.find((item) => item.couponName === coupon.name )
         if (hasIncluded === undefined){
            const user = usersArray.find((user) => user.id === coupon.userId )

            results.push({ name: `${coupon.name} - ${user.name}`, couponName: coupon.name , qtyCoupons: qtyCoupons });
         }
      }
   });

   return results
}

const qtySignaturesByMonth = async () => {
   const signatures = await signaturesRef.get();

   const now = new Date();
   const currentMonth = (now.getMonth() + 1);

   const fourPreviousMonths = getFourPreviousMonths(currentMonth);
   const results = [
      { type: 'currentMonth', month: currentMonth, qty: 0 },
      { type: 'previousMonth', month: fourPreviousMonths[3], qty: 0 },
      { type: 'twoMonthsAgo', month: fourPreviousMonths[2], qty: 0 },
      { type: 'threeMonthsAgo', month: fourPreviousMonths[1], qty: 0 }
   ]

   signatures.forEach((doc) => {
      let item = doc.data();

      const dateString = item.signedDate;
      const date = new Date(dateString);
      const month = date.getMonth() + 1;

      results.forEach((result) => {
         if (result.month === month)
            result.qty++;
      });
   });

   return results;
}

const totalSignaturesByMonth = async () => {
   const signatures = await signaturesRef.get();

   const now = new Date();
   const currentMonth = (now.getMonth() + 1);

   const fourPreviousMonths = getFourPreviousMonths(currentMonth);
   const results = [
      { type: 'currentMonth', month: currentMonth, total: 0, percentage: 0 },
      { type: 'previousMonth', month: fourPreviousMonths[3], total: 0, percentage: 0 },
      { type: 'twoMonthsAgo', month: fourPreviousMonths[2], total: 0, percentage: 0 },
      { type: 'threeMonthsAgo', month: fourPreviousMonths[1], total: 0, percentage: 0 },
      { type: 'fourMonthsAgo', month: fourPreviousMonths[0], total: 0, percentage: 0 },
   ]

   signatures.forEach((doc) => {
      let item = doc.data();

      const dateString = item.lastPlatformPaymentDate;
      const date = new Date(dateString);
      const month = date.getMonth() + 1;

      results.forEach((result) => {
         if (result.month === month)
            result.total += item.price;
      });
   });

   results.forEach((item, index) => {
      if (index < 4) {
         if (item.total > 0 && results[index + 1].total === 0)
            item.percentage = item.total;
         else if (item.total === 0 && results[index + 1].total === 0)
            item.percentage = 0;
         else
            item.percentage = ((item.total - results[index + 1].total) / results[index + 1].total) * 100;
      }
   });

   results.pop();
   return results.reverse();
}

const totalComments = async () => {
   const comments = await commentsRef.get();
   const total = comments.size;

   return total;
}

const totalSignatures = async () => {
   const users = await usersRef.get();
   let total = 0;

   users.forEach((doc) => {
      let item = doc.data();
      if (item.roles.subscriptionType === 'paying')
         total++;
   });

   return total;
}

const totalClients = async () => {
   const users = await usersRef.get();
   let total = 0;

   users.forEach((doc) => {
      let item = doc.data();
      if (item.profile === 'client')
         total++;
   });

   return total;
}

export default {
   getQtyOfTimesByMonthThePartnerCouponWasUsed,
   getTotalOfTimesThePartnerCouponWasUsed,
   getTableInfo,
   qtySignaturesByMonth,
   totalSignaturesByMonth,
   totalComments,
   totalClients,
   totalSignatures
}
