import ArticlesRoute from './../modules/article/router'
import BenefitsRoute from './../modules/benefit/router'
import UsersRoute from './../modules/user/router'
import CategoriesRoute from './../modules/category/router'
import CouponsRoute from './../modules/coupon/router'
import QuestionsRoute from './../modules/question/router'
import ClientRoute from '../modules/signature/router'
import PartnerCompanyRoute from '../modules/partnerCompany/router'

const Dashboard = () => import('../views/Home.vue')
const Reports = () => import('../views/Reports.vue')
const Profile = () => import('../views/Profile.vue')

export default [
   {
      path: '',
      component: () => import('@/templates/Dashboard'),
      children: [
         {
            path: '',
            name: 'dashboard',
            meta: { requiresAuth: true },
            component: Dashboard,
            children: [
               {
                  path: 'dashboard',
                  name: 'dashReports',
                  alias: '/dashboard',
                  component: Reports
               },
               {
                  path: 'profile',
                  name: 'profile',
                  alias: '/profile',
                  component: Profile
               },
               ...ArticlesRoute,
               ...UsersRoute,
               ...CategoriesRoute,
               ...CouponsRoute,
               ...QuestionsRoute,
               ...ClientRoute,
               ...PartnerCompanyRoute,
               ...BenefitsRoute
            ]
         }
      ]
   }
]
