import axiosClient from '@/axios'
import { db } from "../../../../../plugins/firebase";

const routeAuthenticated = '/auth/articles'
const articlesRef = db.collection('articles');
const categoriesArticlesRelationRef = db.collection('categoriesArticlesRelation');

const getAllPaginated = async (pageToken = '', pageSize = 10) => {
   return await axiosClient.get(`${routeAuthenticated}/get-all-paginated?pageSize=${pageSize}&pageToken=${pageToken}`);
}

const getPaginationInfo = async (pageSize = 10) => {
   return await axiosClient.get(`${routeAuthenticated}/get-pagination-info?pageSize=${pageSize}`)
}

const get = async uuid => {
   return await axiosClient.get(`${routeAuthenticated}/get/${uuid}`);
}

const getAllArticlesCategoriesRelation = async () => {
   const categoriesArticlesRelation = await categoriesArticlesRelationRef.get();

   const results = [];
   categoriesArticlesRelation.forEach((doc) => {
      let item = doc.data();

      results.push(item);
   });

   return results;
}

const create = async article => {
   return await axiosClient.post(`${routeAuthenticated}/create`, article);
}

const edit = async article => {
   return await axiosClient.put(`${routeAuthenticated}/edit/${article.uuid}`, article);
}

const editStatus = async (status, uuid) => {
   return await axiosClient.put(`${routeAuthenticated}/edit-status/${uuid}`, {status});
}

const editSubscriptionType = async (subscriptionType, uuid) => {
   return await axiosClient.put(`${routeAuthenticated}/edit-subscription-type/${uuid}`, {subscriptionType});
}

const search = async (title) => {
   const articles = await articlesRef.get();

   const results = [];
   articles.forEach((doc) => {
      let item = doc.data();
      if (item.title) {
         const titleNormalized = item.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
         const searchNormalized = title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

         if (titleNormalized.includes(searchNormalized))
            results.push(item);
      }
   });

   return results;
}

const destroy = async (uuid, allArticlesCategoriesRelation) => {
   const data = {
      allArticlesCategoriesRelation
   };
   return await axiosClient.delete(`${routeAuthenticated}/delete/${uuid}`, { data: data } );
}

export default {
   create,
   get,
   getAllPaginated,
   getPaginationInfo,
   getAllArticlesCategoriesRelation,
   edit,
   editStatus,
   editSubscriptionType,
   search,
   destroy
}
