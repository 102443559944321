export default {
   SET_TABLE_INFO: (state, info) => {
      state.reports.getTableInfo = info;
   },

   SET_TABLE_LABELS: (state, labels) => {
      state.reports.tableLabels = labels;
   },

   SET_TABLE_DATA: (state, data) => {
      state.reports.tableData = data;
   },

   SET_TOTAL_CLIENTS: (state, clients) => {
      state.reports.totalClients = clients;
   },

   SET_TOTAL_COMMENTS: (state, comments) => {
      state.reports.totalComments = comments;
   },

   SET_TOTAL_SIGNATURES: (state, signatures) => {
      state.reports.totalSignatures = signatures;
   },

   SET_TOTAL_SIGNATURES_PER_MONTH: (state, sigsPerMonth) => {
      state.reports.totalSignaturesByMonth = sigsPerMonth;
   },

   SET_QTY_SIGNATURES_PER_MONTH: (state, qtyPerMonth) => {
      state.reports.qtySignaturesByMonth = qtyPerMonth;
   },

   SET_LOADING_INFORMATION: (state, status) => {
      state.reports.loadingInformation = status;
   }
};
