export default {
   articles: {
      allArticlesCategoriesRelation: [],
      pagination: {
         tokenPages: [],
         totalPages: 0,
         currentPage: 1,
      }
   }
}
