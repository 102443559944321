import BranchService from "../services/branch-service";

export default {
   getAllBranches: async ({commit}) => {
      try {
         const response = await BranchService.getAll();
         commit('SET_ALL_BRANCHES', response)

      } catch (e) {
         console.log('Error getAllBranches: ', e)
      } finally {
      }
   }
}
