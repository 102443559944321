import {db} from "../../../../../plugins/firebase";

const branchesRef = db.collection('branches');

const getAll = async () => {
   const branches = await branchesRef.get();

   const results = [];
   branches.forEach((doc) => {
      let item = doc.data();
      results.push(item);
   });

   return results;
}

export default {
   getAll
}
